import React from 'react';
import { Layout } from '../layouts';

const IndexPage = (props) => (
  <Layout location={props.location}>
    <div className="homePage">
      <div className="content-wrap">
        <h2 className="content-h2">Willkommen in der orthopädischen Praxis Dr. Esswein</h2>

        <p>
          Erkrankungen des Bewegungsapparates wie Rückenschmerzen, Ischias, Gelenkbeschwerden, Arthrose, Osteoporose, Freizeit- und Sportunfälle werden hier kompetent behandelt.
        </p>
        {/* <br />
        <p>
          Unser besonderes Anliegen ist mit sanften Methoden wie manueller Medizin, Wärme- und Reizstrombehandlung, Krankengymnastik und Akupunktur Operationen soweit möglich zu vermeiden.
        </p> */}
        <br />
        <p>
          Wir freuen uns auf Ihren Besuch<br /><br />
          Ihr Dr. Esswein mit Praxisteam
        </p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
